@import "main-bx";

.footer {
  position: absolute;
  bottom: 8rem;
  left: 0;
  right: 0;
  z-index: 2;
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3.5rem 6rem;
    background-color: $color-dark;
  }
  &__text {
    width: 40%;
  }
  &__title {
    font-size: 3.6rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0.3rem;
  }
  &__address {
    font-size: 2rem;
  }
  &__info {
    display: flex;
    align-items: center;
  }
  &__link {
    display: flex;
    align-items: center;
    font-size: 2.4rem;
    font-weight: 500;
    &:not(:last-child) {
      margin-right: 10rem;
    }
    img {
      width: 1.8rem;
      flex-shrink: 0;
      margin-right: 1.5rem;
    }
  }
}

@media (max-width: 90em) {
  .footer {
    position: static;
    background-color: $color-dark;
    &__content {
      padding: 4rem 0;
    }
    &__title {
      font-size: 3.2rem;
    }
    &__address {
      font-size: 1.8rem;
    }
    &__link {
      &:not(:last-child) {
        margin-right: 4rem;
      }
      img {
        margin-right: 1rem;
      }
    }
  }
}

@media (max-width: 48em) {
  .footer {
    &__content {
      padding: 4rem 0;
      display: block;
    }
    &__text {
      width: 100%;
      margin-bottom: 3rem;
    }
    &__title {
      margin-bottom: 0.4rem;
    }
    &__link {
      font-size: 2rem;
    }
  }
}

@media (max-width: 36em) {
  .footer {
    &__content {
      padding: 8rem 3.6rem;
    }
    &__text {
      margin-bottom: 8.4rem;
    }
    &__title {
      font-size: 4.4rem;
      span {
        display: block;
      }
      margin-bottom: 0.6rem;
    }
    &__address {
      font-size: 3.6rem;
    }
    &__info {
      display: block;
    }
    &__link {
      font-size: 3.6rem;
      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 1.6rem;
      }
      img {
        width: 3.6rem;
        margin-right: 2rem;
      }
    }
  }
}