@import "main-bx";

.hero {
  position: relative;
  z-index: 1;
  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    img {
      @include cover;
    }
  }
  &__content {
    min-height: 108rem;
    height: 100vh;
    display: flex;
    align-items: flex-end;
    padding: 24rem 0;
  }
  &__text {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__title {
    width: 65%;
    font-size: 8rem;
    font-weight: 700;
    text-transform: uppercase;
  }
}

@media (max-width: 90em) {
  .hero {
    &__content {
      min-height: 94rem;
      padding: 8rem 0;
    }
    &__title {
      font-size: 7rem;
    }
  }
}

@media (max-width: 48em) {
  .hero {
    &__content {
      min-height: 82.6rem;
      height: auto;
      padding: 2.5rem 0;
    }
    &__text {
      display: block;
      text-align: center;
    }
    &__title {
      width: 100%;
      font-size: 6rem;
      margin-bottom: 2.4rem;
    }
  }
}

@media (max-width: 36em) {
  .hero {
    &__content {
      min-height: 102.2rem;
      padding: 4.4rem 0;
    }
    &__title {
      font-size: 6.4rem;
      margin-bottom: 4.4rem;
    }
  }
}