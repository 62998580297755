@font-face {
  font-family: 'Roboto';
  src: local('Roboto Light'), local('Roboto-Light'),
    url('../fonts/Roboto/Roboto-Light.woff2') format('woff2'),
    url('../fonts/Roboto/Roboto-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Regular'), local('Roboto-Regular'),
    url('../fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
    url('../fonts/Roboto/Roboto-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('../fonts/Roboto/Roboto-Medium.woff2') format('woff2'),
    url('../fonts/Roboto/Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('../fonts/Roboto/Roboto-Bold.woff2') format('woff2'),
    url('../fonts/Roboto/Roboto-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}


@mixin roboto {
  font-family: 'Roboto', sans-serif;
}