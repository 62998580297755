@import "main-bx";

.header {
  position: absolute;
  top: 7rem;
  left: 0;
  right: 0;
  z-index: 100;
  @media (max-width: 90em) {
    top: 6rem;
  }
  @media (max-width: 48em) {
    top: 5rem;
  }
  @media (max-width: 36em) {
    top: 6rem;
  }
  &__content {
    display: flex;
    justify-content: flex-end;
  }
  .logo {
    display: inline-block;
    width: 29.7rem;
    @media (max-width: 90em) {
      width: 26rem;
    }
    @media (max-width: 48em) {
      width: 20.8rem;
    }
    @media (max-width: 36em) {
      width: 33.2rem;
    }
  }
}