@import "main-bx";

*, *::before, *::after {
  box-sizing: border-box;
}

html {
  font-family: $font-base;
  font-size: 0.520835vw;
  font-style: normal;
  font-weight: normal;
  -webkit-animation: bugfix infinite 1s;
  line-height: 1.2;
  margin: 0;
  padding: 0;
}

body {
  font-style: normal;
  font-weight: normal;
  -webkit-animation: bugfix infinite 1s;
  line-height: 1.2;
  margin: 0;
  padding: 0;
  color: $color-white;
  background-color: #F4F6FB;
  position: relative;
}

input, textarea {
  -webkit-animation: bugfix infinite 1s;
  line-height: inherit;
  margin: 0;
  padding: 0;
}

a {
  color: unset;
}

a, a:hover {
  text-decoration: none
}

button, input, a, textarea {
  outline: none;
  cursor: pointer;
  font: inherit;
  &:focus, &:active {
    outline: none
  }
}

h1, h2, h3, h4, h5, h6 {
  font: inherit;
  margin: 0;
  padding: 0;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

img {
  width: 100%;
  height: auto;
  display: block;
  // image-rendering: -webkit-optimize-contrast;
}

ul, ol {
  padding: 0;
  margin: 0;
  list-style-type: none;
  li {
    margin: 0;
    padding: 0;
  }
}

.container {
  width: 172rem;
  margin: 0 auto;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

:focus {
  outline-style: none;
  outline-width: 0 !important;
  outline-color: none !important;
}

.o-hidden {
  overflow: hidden;
}



@media (max-width: 90em) {
  html {
    font-size: 0.694vw;
  }

  .container {
    width: 120rem;
  }
}

@media (max-width: 48em) {
  html {
    font-size: 1.302083vw;
  }

  .container {
    width: 100%;
    padding: 0 4rem;
  }
}

@media (max-width: 36em) {
  html {
    font-size: 5px;
    font-size: 1.5625vw;
    font-size: 100 / 375 * 5vw;
    -webkit-text-size-adjust: none;
  }

  body {
    -webkit-text-size-adjust: none;
  }

  .container {
    padding: 0 4.4rem;
  }
}