@import "main-bx";

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32rem;
  height: 6rem;
  padding: 0 2rem;
  border: none;
  border-radius: 0;
  background-color: $color-white;
  font-size: 1.6rem;
  font-weight: 500;
  color: $color-dark;
  &_black {
    background-color: #1F2022;
    color: $color-white;
  }
  @media (max-width: 48em) {
    width: 100%;
  }
  @media (max-width: 36em) {
    height: 12rem;
    font-size: 3.2rem;
  }
}