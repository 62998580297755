@import "main-bx";

.popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-black;
  z-index: 110;
  overflow-y: auto;
  overflow-x: hidden;
  &_active {
    display: block;
  }
  &__wrap {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem;
  }
  &__close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    display: inline-block;
    width: 2.4rem;
    cursor: pointer;
    z-index: 2;
  }
  &__content {
    width: 94rem;
    padding: 8rem 20.7rem;
    background-color: $color-white;
    position: relative;
    z-index: 1;
  }
  &__heading {
    color: $color-black;
    text-align: center;
  }
  &__title {
    font-size: 4.8rem;
    font-weight: 500;
  }
  &__description {
    font-size: 2.4rem;
    font-weight: 300;
    line-height: 1.1;
    margin-top: 6.4rem;
  }
  &__button {
    margin-top: 5rem;
    width: 40rem;
  }
  .form {
    margin-top: 4rem;
  }
  &_thanks {
    .popup {
      &__content {
        padding: 8rem;
      }
    }
  }
}

@media (max-width: 48em) {
  .popup {
    &__content {
      width: 100%;
      padding: 8rem;
    }
    &__button {
      width: 100%;
    }
  }
}

@media (max-width: 36em) {
  .popup {
    &__close {
      width: 4.8rem;
    }
    &__content {
      padding: 8rem 4rem;
    }
    &__title {
      font-size: 6.4rem;
    }
    &__description {
      font-size: 3.6rem;
    }
    .form {
      margin-top: 8rem;
    }
    &_thanks {
      .popup {
        &__content {
          padding: 8rem 4rem;
        }
      }
    }
  }
}