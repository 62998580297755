@import "main-bx";

.form {
  .label {
    display: inline-flex;
    width: 100%;
    height: 5rem;
    position: relative;
    z-index: 1;
    cursor: pointer;
    &:not(:last-child) {
      margin-bottom: 3rem;
    }
    &_checkbox {
      width: auto;
      height: auto;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
    &__box {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      flex-shrink: 0;
      border: 1px solid #313131;
      margin-right: 1.5rem;
      position: relative;
      transition: all $transition;
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1.7rem;
        height: 1.3rem;
        opacity: 0;
        transition: opacity $transition;
        background: url(../img/svg/check.svg) 50% 50% / contain no-repeat;
      }
    }
    &__text {
      font-size: 1.6rem;
      color: #4D4D4D;
      a {
        text-decoration: underline;
      }
    }
  }
  input {
    width: 100%;
    height: 100%;
    padding: 0 1.3rem;
    border: none;
    border-bottom: 1px solid #C8CACB;
    border-radius: 0;
    appearance: none;
    font-size: 1.6rem;
    color: $color-black;
    position: relative;
    transition: all $transition;
    &:checked ~ .label__box {
      &::after {
        opacity: 1;
      }
    }
    &::placeholder {
      color: $color-grey;
    }
    &.visually-hidden {
      position: absolute;
      width: 1px;
      height: 1px;
      margin: -1px;
      border: 0;
      padding: 0;
      clip: rect(0 0 0 0);
      overflow: hidden;
    }
  }
  &__privacy {
    display: flex;
    margin-top: 4rem;
  }
  &__button {
    width: 100%;
    margin-top: 6rem;
  }
}

@media (max-width: 36em) {
  .form {
    .label {
      height: 10rem;
      &:not(:last-child) {
        margin-bottom: 6rem;
      }
      &__box {
        width: 4.8rem;
        height: 4.8rem;
        margin-right: 3rem;
        &::after {
          width: 3.4rem;
          height: 2.6rem;
        }
      }
      &__text {
        font-size: 3.2rem;
      }
    }
    input {
      padding: 0 2.6rem;
      font-size: 3.2rem;
    }
    &__privacy {
      margin-top: 8rem;
    }
    &__button {
      margin-top: 12rem;
    }
  }
}